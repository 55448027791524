<!--[月费版]筛选到期时间用户-->
<template>
    <div class="Tools">
        <el-dropdown>
            <el-button class="el-dropdown-link">
                {{lang['html.button.filter']}}
                <el-icon class="el-icon--right">
                    <arrow-down/>
                </el-icon>
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="dialogVisible = true">{{lang['html.dropdown-item.day']}}</el-dropdown-item>
                    <el-dropdown-item @click="filterUser(-1)">{{lang['html.dropdown-item.overdue']}}</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>

        <el-dialog
            v-model="dialogVisible"
            title="Filter"
            width="30%"
        >
            <el-input-number v-model="filterDay" :min="1" :max="9999999" />
            <br>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">{{lang['html.button.return']}}</el-button>
                    <el-button type="primary" @click="filterUser(filterDay);dialogVisible=false">{{lang['html.button.submit']}}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "userList-filterMoneyTime",
    props:['userListArr',],
    emits:['recvFilterUserMoneyTime'],
    data(){
        return {
            nowTime:new Date(),
            dialogVisible:false,
            filterDay:0,
            userArr:[],
            lang:this.$lang.getLang('user','userList-filterMoneyTime'),
        }
    },
    methods:{
        /**
         * 计算用户剩余天数
         * @param time 用户到期时间
         * @return {number}
         * 已过期返回-1,未过期则返回计算后的天数
         */
        calcUserTimeByDay(time){
            let obj = new Date(time);
            let int = obj.getTime() - this.nowTime.getTime();
            if (int <=0){
                //已过期
                return -1;
            }
            return parseInt((int/(24*1000*3600)));
        },
        filterUser(day){
            if (this.userArr.length <= 0){
                this.userArr = this.userListArr;
            }
            this.$notify({message:this.lang['filterUser.wait'],type:'info'});
            let bool;
            let res = [];
            for (let userArrKey in this.userArr) {
                bool = false;
                let user = this.userArr[userArrKey];
                let int = this.calcUserTimeByDay(user.money_time);
                if (day === -1){
                    //只筛选过期的
                    if (int < 0){
                         bool = true;
                    }
                }
                if (int >=0 && int <= day){
                    bool = true;
                }
                if (bool){
                    res.push(user);
                }
            }
            this.$emit('recvFilterUserMoneyTime',res);
            setTimeout(()=>{
                this.$notify({message:this.lang['filterUser.success'] + res.length,type:'success'});
            },800)
        },
    },
    mounted() {
        this.userArr = this.userListArr;
    }
}
</script>

<style scoped>

</style>