// 一些账号管理类
import cookies from "./cookies";
const Base64 = require('js-base64').Base64;
const func = {
    /**
     * 获取已登录的代理商账号名字
     * @returns {null|*}
     * 成功返回用户名 失败返回null
     */
    getUserName(){
        let token = cookies.getToken();
        if (token == null || token.length<30){
            return null;
        }
        let str = Base64.decode(token);
        let obj = JSON.parse(str);
        if (obj.userName != null && obj.userName.length>1){
            return obj.userName
        }
        return null;
    }
}
export default func;